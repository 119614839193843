import React, { useEffect, useMemo, useState } from "react";

interface IRunningNumbersAnimationProps {
  stringToAnimate: string;
  animationDuration?: number;
  changeSpeed?: number;
  shouldAnimate?: boolean;
}

export const RunningNumbersAnimation: React.FC<
  IRunningNumbersAnimationProps
> = ({
  stringToAnimate = "",
  animationDuration = 2000,
  changeSpeed = 50,
  shouldAnimate = true,
}) => {
  const [animatedString, setAnimatedString] = useState(stringToAnimate);

  const intervalId = useMemo(
    () =>
      setInterval(() => {
        const newAnimatedString = stringToAnimate
          .split("")
          .map((digit) => {
            if (!Number.isNaN(parseInt(digit))) {
              return Math.floor(Math.random() * 9);
            }

            return digit;
          })
          .join("");

        setAnimatedString(newAnimatedString);
      }, changeSpeed),
    [stringToAnimate],
  );

  useEffect(() => {
    setTimeout(() => {
      clearInterval(intervalId);
      setAnimatedString(stringToAnimate);
    }, animationDuration);
  }, [intervalId]);

  return shouldAnimate ? animatedString : stringToAnimate;
};
