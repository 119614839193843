import React from "react";
import { twMerge } from "tailwind-merge";
import IconButton from "../button/IconButton";
import { IconFont } from "../iconFont";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  onPageChange,
}) => {
  const handlePageClick = (page: number) => {
    onPageChange(page);
  };

  const getMiddlePages = (isMobile: boolean) => {
    const offset = isMobile ? 3 : 4;

    let middlePage = currentPage >= offset ? currentPage : offset;

    middlePage =
      currentPage <= totalPages - offset ? middlePage : totalPages - offset + 1;

    return [
      middlePage - 2,
      middlePage - 1,
      middlePage,
      middlePage + 1,
      middlePage + 2,
    ]
      .filter((_, index) => {
        return (index > 0 && index < 4) || !isMobile;
      })
      .map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => handlePageClick(pageNumber)}
          className={currentPage === pageNumber ? "active" : ""}
        >
          {pageNumber}
        </button>
      ));
  };

  const getPages = (isMobile = false) => {
    const offset = isMobile ? 3 : 4;

    return [
      <button
        key="prev_page"
        onClick={() => handlePageClick(currentPage - 1)}
        disabled={currentPage === 1}
        className="hidden sm:flex"
      >
        <IconFont name="arrow_left" />
      </button>,
      <button
        key={1}
        onClick={() => handlePageClick(1)}
        className={currentPage === 1 ? "active" : ""}
        disabled={currentPage === 1}
      >
        1
      </button>,
      ...(totalPages > 5 && currentPage >= offset
        ? [
            <button key="currentPage>" disabled={true}>
              {"..."}
            </button>,
          ]
        : []),
      getMiddlePages(isMobile),
      ...(totalPages > 5 && currentPage <= totalPages - offset
        ? [
            <button key="currentPage<" disabled={true}>
              {"..."}
            </button>,
          ]
        : []),
      <button
        key={totalPages}
        onClick={() => handlePageClick(totalPages)}
        className={currentPage === totalPages ? "active" : ""}
        disabled={currentPage === totalPages}
      >
        {totalPages}
      </button>,
      <button
        key="next_page"
        onClick={() => handlePageClick(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="hidden sm:flex"
      >
        <IconFont name="arrow_right" />
      </button>,
    ];
  };

  return (
    <div
      className={twMerge(
        "[&>*]:gap-2 [&>*]:flex-wrap [&>*>button]:items-center [&>*>button]:justify-center",
        "[&>*>button]:min-w-9 [&>*>button]:h-9 [&>*>button]:bg-gray-700 [&>*>button]:rounded-md [&>*>button]:px-2",
        "[&>*>button]:font-normal [&>*>button]:text-sm [&>*>button.active]:bg-brand-pink-normal",
      )}
    >
      <div className="hidden sm:flex justify-end">{getPages()}</div>
      <div className="flex sm:hidden justify-center">{getPages(true)}</div>
    </div>
  );
};
