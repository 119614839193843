import { useContext, useEffect, useMemo, useState } from "react";
import { PlayerContext } from "../contexts/PlayerContext";
import { truncatePubkey } from "../utils/string/string";
import { WrappedWalletContext } from "../contexts/WrappedWalletContext";

export enum AvatarType {
  Zeebro = "Zeebro",
  SVG_STRING = "SVG String",
}

export const useUserMeta = () => {
  const { playerAccount, playerMeta, loadedPlayerAccount, counter } =
    useContext(PlayerContext);
  const { walletPubkey } = useContext(WrappedWalletContext);

  const username = useMemo(() => {
    if (!loadedPlayerAccount) {
      return;
    }

    if (
      playerAccount != null &&
      playerAccount.username != null &&
      playerAccount.username.length > 0
    ) {
      return playerAccount.username;
    }

    if (
      playerMeta != null &&
      playerMeta.username != null &&
      playerMeta.username.length > 0
    ) {
      return playerMeta.username;
    }

    if (walletPubkey != null) {
      return truncatePubkey(walletPubkey.toString(), 3);
    }
  }, [playerAccount, walletPubkey, loadedPlayerAccount, playerMeta, counter]);

  return useMemo(() => {
    return {
      userName: username,
      playerAccountPublicKey: playerAccount?.publicKey,
      playerAccountOwnerPublicKey: playerAccount?.ownerPubkey,
      avatar: playerAccount?.avatar,
    };
  }, [username, playerAccount]);
};
